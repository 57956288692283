export const adjustData = () => {
    let otherAdjustments = localStorage.getItem("otherAdjustments");
    let tdsadjustments = localStorage.getItem("tdsadjustments");
    let OtherAdjValue = otherAdjustments === 'true' ? true : false
    let TDSAdjValue = tdsadjustments === 'true' ? true : false
    let AdjustOBJ = {
        OtherAdjValue: OtherAdjValue,
        TDSAdjValue: TDSAdjValue
    }
    return AdjustOBJ
}

export const isRoundOff = (Amount) => {
    let roundOff = localStorage.getItem("isRoundOffTotals");
    let AMTValue = roundOff === "true" ? Math.round(Amount) : parseFloat(Amount).toFixed(2)
    return AMTValue
}


export const handleConfigDataMapping = (result)=>{
    if(result.length>0){
    localStorage.setItem('isRoundOffTotals', result === null ? false : result[0].autoRoundOffTotals);
    localStorage.setItem('negativeQuantity', result === null ? false : result[0].isAllowNegative);
    localStorage.setItem('tdsadjustments', result === null ? false : result[0].tdsadjustments);
    localStorage.setItem('otherAdjustments', result === null ? false : result[0].otherAdjustments);
    localStorage.setItem('currencyCode', result === null ? '1' : result[0].currencyCode);
    localStorage.setItem('dateFormat', result === null ? '2' : result[0].dateFormat)
    localStorage.setItem('inventoryTracking', result === null ? false : result[0].trackInventory)
    localStorage.setItem('enablebarcoding', result === null ? false : result[0].enableBarCode)
    localStorage.setItem('enablebatches', result === null ? false : result[0].enableBatch)
    localStorage.setItem('enableserialization', result === null ? false : result[0].enableSerialization)
    localStorage.setItem('enableshelflife', result === null ? false : result[0].enableShelfLife)
    localStorage.setItem('enablevariants', result === null ? false : result[0].enableVariantsBasedSku)
    localStorage.setItem('isMultiItemsInvoice', result === null ? false : result[0].isMultiItemsInvoice)
    localStorage.setItem('activeTemplateId', result === null ? false : result[0].activeTemplateId)
    localStorage.setItem('ecommerce',true) // result === null  ? false : result.ecommerce === undefined?false:result.ecommerce)
    }else{
        localStorage.setItem('isRoundOffTotals', result === null ? false : result.autoRoundOffTotals);
        localStorage.setItem('negativeQuantity', result === null ? false : result.isAllowNegative);
        localStorage.setItem('tdsadjustments', result === null ? false : result.tdsadjustments);
        localStorage.setItem('otherAdjustments', result === null ? false : result.otherAdjustments);
        localStorage.setItem('currencyCode', result === null ? '1' : result.currencyCode);
        localStorage.setItem('dateFormat', result === null ? '2' : result.dateFormat)
        localStorage.setItem('inventoryTracking', result === null ? false : result.trackInventory)
        localStorage.setItem('enablebarcoding', result === null ? false : result.enableBarCode)
        localStorage.setItem('enablebatches', result === null ? false : result.enableBatch)
        localStorage.setItem('enableserialization', result === null ? false : result.enableSerialization)
        localStorage.setItem('enableshelflife', result === null ? false : result.enableShelfLife)
        localStorage.setItem('enablevariants', result === null ? false : result.enableVariantsBasedSku)
        localStorage.setItem('isMultiItemsInvoice', result === null ? false : result.isMultiItemsInvoice)
        localStorage.setItem('activeTemplateId', result === null ? false : result.activeTemplateId)
        localStorage.setItem('ecommerce',true)// result === null  ? false : result.ecommerce === undefined?false:result.ecommerce)
    }
}
