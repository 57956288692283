import React, { useState, useEffect } from 'react'
import AdqModel from '../app/commonControls/AdqModel/AdqModel'
import { getBroadcastContent } from '../_services/HelpServivce'
import { Button, Modal } from 'antd'

const modelSttyle = {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: "50%",
    padding: "0px",
    marginTop : '50%'
}


function BroadcastModel() {
    const [state, setState] = useState(null)
    const [ visable, setVisable ] = useState(false)
    const [floatMsg, setFloatMsg] = useState({title:'',floatingMessage:''})

    useEffect(() => {
        callBroadcastApi()
    }, [])
    const callBroadcastApi = async () => {
        await getBroadcastContent().then(res => {
            if (res.status === 200 && res?.data?.result?.success) {
                const response = res.data.result.details
                // console.log('first', res, new Date(response.validTo).getTime(), '===== ', new Date().getTime())

                if (new Date(response.validTo).getTime() > new Date().getTime()) {
                    setState(response)
                    setVisable(true)
                    setFloatMsg(response)
                    // changeMargin()
                }
            }
        })

    }
    return (
        // <div style={{ display: "flex", justifyContent: "left", marginTop: "30%"}}>
        <Modal className='broadcastModel' id='broadcastModelid' centered open={visable} footer={null} maskClosable={true} mask={true} onCancel={()=>setVisable(false)} >
            <div>
            {/* <img src="/images/year.jpg" alt='' width={450} height={200}/> */}
            <div style={{height:'5px'}}></div>
                <div style={{textAlign:'end'}}>
                    <div style={{position:'relative',zIndex:10,marginBottom:'-30px',marginRight:'10px'}}>
            <i className="fas fa-times" style={{ color: '#000', backgroundColor: '#fff', padding:'5px',borderRadius:'5px' }} 
                 onClick={()=>setVisable(false)} ></i>
                 </div>
                 </div>
                 <div className=''  style={{ textAlign:'center'}}>
                 <img src="/images/year2025.png" alt='/images/uBooks_360.jpg' style={{width:'100%',height:'80%'}}/>
                 <div className=''  style={{background:'#b8ece0', padding:'10px'}}>
                {/* <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}> */}
                <p className = 'broadtittle' style={{fontSiz:'25px',fontWeight:'600',marginBottom:'5px'}}>{floatMsg.title} </p>
                
                {/* </div> */}
                <p className = 'broadmsg' style={{marginBottom:'0px'}}>{floatMsg.floatingMessage}</p>
                </div>
                 </div>
              
                {/* <div style={{display:'flex',width:'100%',justifyContent:'space-between'}}>
                    <div style={{width:'32%',background:'#ff1f7e',height:'3px'}}></div>
                    <div style={{width:'32%',background:'#39b62c',height:'3px'}}></div>
                    <div style={{width:'32%',background:'#ff1f7e',height:'3px'}}></div>
                </div> */}
                </div>
        </Modal>
        // </div>
    )
  return (
    <div>
       {
        state &&  <AdqModel containerClass={modelSttyle} isopen={visable}
        content={()=>(
            <div>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <h4>{floatMsg.title}</h4>
                <Button>
                 <i className="fas fa-times recallClose" style={{ color: '#000', backgroundColor: '#fff', fontSize: '20px', right: '20px', top: '22px' }} 
                 onClick={()=>setVisable(false)} ></i>
                 </Button>
                </div>
                <p>{floatMsg.floatingMessage}</p>
                </div>


        )}
        />
       }
    </div>
  )
}

export default BroadcastModel