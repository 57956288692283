import {lazy} from "react";
import HigherOrderComponent from "../_helper/HigherOrderComponent";

import LayoutPage from "./LayoutPage";
// Grouping Controls
const AdqAddressControl = lazy(()=>import('./GroupingControls/AdqAddressControl/AdqAddressControl'));

// Common Reports
const CommonReports = lazy(()=>import('./Reports/CommonReports/CommonReports'));

// Masters
const  SupplyMasterList = lazy(()=>import("./Masters/SupplyMaster/SupplyMasterList"));
const  PartyMasterNew = lazy(()=>import('./Masters/PartyMaster/PartyMasterNew'));
const  PartyMasterB2c = lazy(()=>import('./Masters/PartyMaster/PartyMasterB2c'));
const  PartyMasterList = lazy(()=>import('./Masters/PartyMaster/PartyMasterList'));
const  SupplierMasterList = lazy(()=>import('./Masters/PartyMaster/SupplierMasterList'));
const  SupplyMasterNew = lazy(()=>import("./Masters/SupplyMaster/SupplyMasterNew"));
const  UOMlanding = lazy(()=>import('./Masters/UnitConversations/UOMlanding'));
const  UOMnew = lazy(()=>import('./Masters/UnitConversations/UOMnew'));

// Account
const  ChartOfAccounts = lazy(()=>import("./Accounting/ChartAccounts/ChartofAccounts"));
const  ChartOfAccountsNewEdit = lazy(()=>import("./Accounting/ChartAccounts/ChartOfAccountsNewEdit"));
const  JournalVoucherView = lazy(()=>import("./Accounting/JournalVoucher/JournalVoucherView"));
const  Ledgerposting = lazy(()=>import("./Accounting/LedgerPostings/Ledgerposting.jsx"));
const  Ledgerpostingcreation = lazy(()=>import("./Accounting/LedgerPostings/Ledgerpostingcreation.jsx"));
const  JournalVoucherCreation = lazy(()=>import("./Accounting/JournalVoucher/JournalVoucherCreation"));
const  Ledgers = lazy(()=>import('./Accounting/Ledgers/LedgerList'));
const  LedgerCreation = lazy(()=>import('./Accounting/Ledgers/LedgerCreation'));
const  AccountServiceGL = lazy(()=>import('./Accounts/GeneralLedger/AccountServiceGL'));
const  MSIReports = lazy(()=>import('./MSIReports/MsiReports'));
const  MISReports = lazy(()=>import('./MSIReports/MISReports.jsx'));
const  ReportsCommon = lazy(()=>import('./MSIReports/ReportsCommon'));
const  ReportsDateSelection = lazy(()=>import("./AccountReports/ReportsDateSelection"));
// Organization
const  OrgProfile = lazy(()=>import('./Organization/ManageLocations/OrgProfile'));
const  OrgEdit = lazy(()=>import('./Organization/ManageLocations/Orgedit'));
// const  OrgProfileNew = lazy(()=>import('./Organization/ManageLocations/OrgProfileNew'));
const  userslist = lazy(()=>import('./Organization/Users/UsersList'));
const  groupslist = lazy(()=>import('./Organization/Groups/GroupsList'));
const  usersedit = lazy(()=>import('./Organization/Users/UsersEdit'));
const  groupsedit = lazy(()=>import('./Organization/Groups/GroupsEdit'));
const  ManageRoleNew = lazy(()=>import('./Organization/ManagerRoles/ManageRoleNew'));
const  ManageRole = lazy(()=>import('./Organization/ManagerRoles/ManageRole'));
const  DataImport  = lazy(()=>import('./DataManagement/Import/DataImport'));

// Inventory
const  SupplyAdjustment = lazy(()=>import('./Inventory/InventoryAdjustment/SupplyAdjustment'));
const  supplypreview = lazy(()=>import('./Inventory/InventoryAdjustment/supplypreview'));
const  OpeningStock = lazy(()=>import('./Inventory/ManageInventory/OpeningStock'));
const  AllOpeningStock = lazy(()=>import('./Inventory/ManageInventory/AllOpeningStock'));
const  ImportPage = lazy(()=>import('./Inventory/ManageInventory/Import'));
const  JournalsNew = lazy(()=>import('./Adjustments/ManualJournals/JournalsNew'));
const  JournalsList = lazy(()=>import('./Adjustments/ManualJournals/JournalsList'));

const  openingBalance = lazy(()=>import('./Accounts/OpeningBalance/openingBalance'));

// Accounting Reports
// const  TrialBalance = lazy(()=>import('./AccoutingReports/TrialsBalance/TrialBalance'));
const  AccountTransactions = lazy(()=>import('./AccoutingReports/AccountTransactions/AccountTransactions'));
// const  JournalReports = lazy(()=>import('./AccoutingReports/JournalReports/JournalReports'));
const  profitandloss = lazy(()=>import('./AccoutingReports/ProfitandLoss/profitandloss'));
// const  balancesheet = lazy(()=>import('./AccoutingReports/BalanceSheet/balancesheet'));
const  noteno = lazy(()=>import('./AccoutingReports/NoteNo/noteno'));
const  OutStandingCreditors = lazy(()=>import('./AccoutingReports/OutStanding/OutStandingCreditors'));
const  OutStandingDebitors = lazy(()=>import('./AccoutingReports/OutStanding/OutStandingDebitors'));

// Catalogue
const  ChannelDetails = lazy(()=>import('./Masters/SupplyMaster/ChannelDetails.jsx'));
const  AddChannelDetail = lazy(()=>import('./Catalogue/AddChannelDetails'));
const  AddCatelogueDetails = lazy(()=>import('./Catalogue/AddCatelogueDetails'));
const  CatelogueDetails = lazy(()=>import('./Catalogue/CatelogueDetails'));
const  CatelogueAllocation = lazy(()=>import('./Catalogue/CatelogueAllocation'));
const  AddCatelogueAllocationDetails = lazy(()=>import('./Catalogue/AddCatelogueAllocation'));
const  CatelogueItemMapping = lazy(()=>import('./Catalogue/CatelogueItemMapping'));
const  MappedChannels = lazy(()=>import('./Catalogue/MappedChannels'));
const  Overrides = lazy(()=>import('./Catalogue/Overrides'));
const  AddCatelogueItemMapping = lazy(()=>import('./Catalogue/AddCatelogueItemMapping'));
const  CatelogueMapping = lazy(()=>import('./Catalogue/CatelogueMapping'));
const  CatelogueCreation = lazy(()=>import('./Catalogue/CatelogueCreation'));

const  MasterCatalogue = lazy(()=>import('./Catalogue/MasterCatalogue/MasterCatalogue'));

//expense
const  ExpenseList = lazy(()=>import('./Transactions/RecordExpenses/ExpenseList'));
const  ExpenseNew = lazy(()=>import('./Transactions/RecordExpenses/ExpenseNew'));
const  ExpensePreview = lazy(()=>import('./Transactions/RecordExpenses/ExpensePreview'));

// Sale Transactions
const  SaleView = lazy(()=>import('./Transactions/Sale/SaleInvoice/SaleView'));
const  QuotationView = lazy(()=>import('./Transactions/Sale/Quotation/QuotationView'));
const  SaleInvoiceCreate = lazy(()=>import("./Transactions/Sale/SaleInvoice/SaleInvoiceNew"));
const  SaleInvoiceList = lazy(()=>import('./Transactions/Sale/SaleInvoice/SaleInvoicelist'));
const  Receipt = lazy(()=>import('./Transactions/Sale/Receipts/Receipt'));
const  ReceiptNew = lazy(()=>import('./Transactions/Sale/Receipts/ReceiptNew'));
const  ReceiptPreview = lazy(()=>import('./Transactions/Sale/Receipts/receiptPreview'));
const  SaleReturn = lazy(()=>import('./Transactions/Sale/SaleReturns/SaleReturn'));
const  SaleReturnNew = lazy(()=>import('./Transactions/Sale/SaleReturns/SaleReturnNew'));
const  SaleDebitNote = lazy(()=>import('./Transactions/Sale/SaleDebitNote/SaleDebitNote'));
const  SaleDebitNoteNew = lazy(()=>import('./Transactions/Sale/SaleDebitNote/SaleDebitNoteNew'));
const  SaleEwayBill = lazy(()=>import('./Transactions/Sale/SaleEwaybill/SaleEWayBill'));
const  SaleCreditView = lazy(()=>import('./Transactions/Sale/SaleCreditNote/SaleCreditView'));
const  SaleDebitView = lazy(()=>import('./Transactions/Sale/SaleDebitNote/SaleDebitView'));
const  SaleReturnView = lazy(()=>import('./Transactions/Sale/SaleReturns/SaleReturnView'));
const  SaleCreditNote = lazy(()=>import('./Transactions/Sale/SaleCreditNote/SaleCreditNote'));
const  SaleCreditNoteNew = lazy(()=>import('./Transactions/Sale/SaleCreditNote/SaleCreditNoteNew'));
const  SaleCustomerInvoice = lazy(()=>import('./Transactions/Sale/SaleInvoice/SaleCustomerInvoice'));
const  SaleOrderList = lazy(()=>import('./Transactions/Sale/SaleOrders/SaleOrderList'));
const  SaleOrderNew = lazy(()=>import('./Transactions/Sale/SaleOrders/SaleOrderNew'));
const  SaleOrderView = lazy(()=>import('./Transactions/Sale/SaleOrders/SaleOrderView'));
const  saleB2CView = lazy(()=>import('./Transactions/Sale/SaleInvoice/saleB2CView'));
const  ClientUsers = lazy(()=>import('./Transactions/Sale/ClientUsers/ClientUsers'));
const  KSACreation = lazy(()=>import("./Transactions/Sale/KSA/KSACreation"));
const PoSNewCreation = lazy(()=>import("./Transactions/Sale/SaleInvoice/PoSNewCreation"));



const  PurchaseOrderview = lazy(()=>import('./Transactions/Purchases/PurchaseOrders/PurchaseOrderview'));
const  OverseasSaleList = lazy(()=>import('./Transactions/Sale/OverseasSale/OverseasSaleList'));
const  OverseasSaleNew = lazy(()=>import('./Transactions/Sale/OverseasSale/OverseasSaleNew'));
const  OverseasSaleView = lazy(()=>import('./Transactions/Sale/OverseasSale/OverseasSaleView'));

// purchase Transactions
const  Payments = lazy(()=>import('./Transactions/Purchases/Payments/Payments'));
const  paymentNew = lazy(()=>import('./Transactions/Purchases/Payments/PaymentNew'));
const  Preview = lazy(()=>import('./Transactions/Purchases/Payments/Preview'));
const  PurchaseOrder = lazy(()=>import('./Transactions/Purchases/PurchaseOrders/PurchaseOrder'));
const  PurchaseOrderNew = lazy(()=>import('./Transactions/Purchases/PurchaseOrders/PurchaseOrderNew'));
const  PurchaseInvoice = lazy(()=>import('./Transactions/Purchases/PurchaseBills/PurchaseInvoice'));
const  PurchaseInvoiceNew = lazy(()=>import('./Transactions/Purchases/PurchaseBills/PurchaseInvoiceNew'));
const  OverseasPurchaseList = lazy(()=>import('./Transactions/Purchases/PurchaseBills/OverseasPurchaseList'));
const  OverseasPurchaseNew = lazy(()=>import('./Transactions/Purchases/PurchaseBills/OverseasPurchaseNew'));
const  OverseasPurchaseView = lazy(()=>import('./Transactions/Purchases/PurchaseBills/OverseasPurchaseView'));
const  QuotationList = lazy(()=>import('./Transactions/Sale/Quotation/QuotationList'));
const  QuotationParent = lazy(()=>import('./Transactions/Sale/Quotation/QuotationParent'));
const  PurchaseView = lazy(()=>import('./Transactions/Purchases/PurchaseBills/PurchaseView'));
const  PurchaseReturn = lazy(()=>import('./Transactions/Purchases/PurchaseReturns/PurchaseReturn'));
const  PurchaseReturnNew = lazy(()=>import('./Transactions/Purchases/PurchaseReturns/PurchaseReturnNew'));
const  PurchaseCreditNote = lazy(()=>import('./Transactions/Purchases/PurchaseCreditNote/PurchaseCreditNote'));
const  PurchaseCreditView = lazy(()=>import('./Transactions/Purchases/PurchaseCreditNote/PurchaseCreditView'));
const  PurchaseCreditNoteNew = lazy(()=>import('./Transactions/Purchases/PurchaseCreditNote/PurchaseCreditNoteNew'));
const  PurchaseDebitview = lazy(()=>import('./Transactions/Purchases/PurchaseDebitNote/PurchaseDebitView'));
const  PurchaseDebitNote = lazy(()=>import('./Transactions/Purchases/PurchaseDebitNote/PurchaseDebitNote'));
const  PurchaseDebitNoteNew = lazy(()=>import('./Transactions/Purchases/PurchaseDebitNote/PurchaseDebitNoteNew'));
const  InvoiceBuilder = lazy(()=>import("./Transactions/invoicebuilder"));

//E-invoice
const  EInvoiceListView = lazy(()=>import('./E-InvoiceList/EInvoiceListView'));

// EwayBill
const  NicConfiguration = lazy(()=>import('./E-wayBill/NicConfig/NicConfiguration'));
const  EWayBill = lazy(()=>import('./E-wayBill/E-WayBill/E-Way'));

// Configurations
const  AdditionalField = lazy(()=>import('./Configuration/AdditionalFields/AdditionalField'));
const  AdditionalFieldNew = lazy(()=>import('./Configuration/AdditionalFields/AdditionalFieldNew'));
const  DoumentHome = lazy(()=>import("./Configuration/NumberSeries/DoumentLanding"));
// const  GeneralConfig = lazy(()=>import('./Configuration/General/GeneralConfig'));
const  ConfigurationNew = lazy(()=>import('./Configuration/General/ConfigurationNew'));
const  GeneralConfigNew = lazy(()=>import('./Configuration/General/GeneralConfigNew'));
const  DocumentNew = lazy(()=>import("./Configuration/NumberSeries/DocumentNew"));
const  Attributes = lazy(()=>import("./Configuration/Attributes/Attributes"));
const  Attributecreation = lazy(()=>import("./Configuration/Attributes/Attributecreation"));
const  CategoryDefination = lazy(()=>import("./Configuration/Categories/CategoryDefination"));

// Data Management
const  FileImportList = lazy(()=>import('./DataManagement/Import/FileImportList'));
const  FileImportNew = lazy(()=>import('./DataManagement/Import/FileImportNew'));
const  UseCreditData = lazy(()=>import("./Transactions/Sale/SaleInvoice/UseCreditData"));
const  CashSaleCreate = lazy(()=>import("./Transactions/Sale/Cash Sales/cashSales"));
const  BranchTransfer = lazy(()=>import("./Transactions/Sale/Cash Sales/BranchTransfer"));
const  BranchTransferList = lazy(()=>import("./Transactions/Sale/Cash Sales/BranchTransferList"));

const  AdvancePayments = lazy(()=>import("./Transactions/Purchases/AdvancePayments/AdvPaymentsList"));
const  AdvPaymentsNew = lazy(()=>import("./Transactions/Purchases/AdvancePayments/AdvPaymentsNew"));

const  AdvanceReceipts = lazy(()=>import("./Transactions/Sale/AdvanceReceipts/AdvReceiptsList"));
const  AdvReceiptNew = lazy(()=>import("./Transactions/Sale/AdvanceReceipts/AdvReceiptsNew"));

const  TransferView = lazy(()=>import("./Transactions/Sale/Cash Sales/TransferView"));
const  TransferChallan = lazy(()=>import("./Transactions/Sale/Cash Sales/TransferChallan"));
const  CashBank = lazy(()=>import("./AccoutingReports/CashBank/CashBank"));
const  TradeReceivables = lazy(()=>import("./AccoutingReports/Trades/TradeReceivables"));
const  TradePayables = lazy(()=>import("./AccoutingReports/Trades/TradePayables"));
const  CashSalesList = lazy(()=>import("./Transactions/Sale/Cash Sales/CashSalesList"));
const  CashSalesView = lazy(()=>import("./Transactions/Sale/Cash Sales/CashSalesView"));
const  SaleCustomerList = lazy(()=>import("./Transactions/Sale/SaleInvoice/SaleCustomerList"));

const  ProductListing = lazy(()=>import('./ProductDetailsPage/ProductListing'));
const  ProductDetails = lazy(()=>import('./ProductDetailsPage/ProductDetails'));
const  Gstr3B = lazy(()=>import("./GstReturns/GstReturns/Gstr3B"));
const  Gstr1 = lazy(()=>import("./GstReturns/GstReturns/Gstr1"));
const  GstReturns = lazy(()=>import("./GstReturns/GstReturns/GstReturns"));
const  GstAdjustmentNew = lazy(()=>import("./GstReturns/GstAdjustment/GstAdjustmentNew"));
const  PurchaseReturnView = lazy(()=>import("./Transactions/Purchases/PurchaseReturns/PurchaseReturnView"));
const  AdvanceReceiptsView = lazy(()=>import("./Transactions/Sale/AdvanceReceipts/AdvanceReceiptsView"));
const  AdvancePaymentsView = lazy(()=>import("./Transactions/Purchases/AdvancePayments/AdvancePaymentsView"));
// const  WorkflowOrderlist = lazy(()=>import("./Transactions/Sale/WorkflowOrder/WorkflowOrderlist"));
// const  WorkflowOrderView = lazy(()=>import("./Transactions/Sale/WorkflowOrder/WorkflowOrderView"));
const  WorkflowOrderNew = lazy(()=>import("./Transactions/Sale/WorkflowOrder/WorkflowOrderNew"));
const  ReturnOrderList = lazy(()=>import("./Transactions/Sale/WorkflowOrder/ReturnOrderList"));
const  ReturnOrderView = lazy(()=>import("./Transactions/Sale/WorkflowOrder/ReturnOrderView"));
const  Subscriptions = lazy(()=>import('./Organization/Subscriptions/Subscriptions'));

const  InventoryDashboard = lazy(()=>import("./Inventory/InventoryDashboard"));
const  Registration = lazy(()=>import("./Organization/Registration/Registration"));
const  RegistrationCreation = lazy(()=>import("./Organization/Registration/RegistrationCreation"));

const  PriceOveride = lazy(()=>import("./Organization/PriceOverride/PriceOveride"));
const  PriceOverideCreation = lazy(()=>import("./Organization/PriceOverride/PriceOverideCreation"));
const  UserNewEdit = lazy(()=>import("./Organization/Users/UserNewEdit"));
const  SharedCatalogue = lazy(()=>import("./Catalogue/SharedCatalogue/SharedCatalogue"));
const  PriceOverrides = lazy(()=>import("./Catalogue/PriceOverrides/PriceOverrides"));
const  RegistrationDashboard = lazy(()=>import("./Organization/Registration/RegistrationDashboard"));
const  DashBoardTab = lazy(()=>import("./Organization/Registration/DashBoardTab"));
const  ChannelOrder = lazy(()=>import("./Transactions/Sale/WorkflowOrder/ChannelOrder"));
const  ChannelOrderList = lazy(()=>import("./Transactions/Sale/ChannelOrder/ChannelOrderList"));
const  OrgLocationNew = lazy(()=>import("./Organization/ManageLocations/OrgLocationNew"));
const  TaxilaCockpit = lazy(()=>import("./GstReturns/TaxillaCockpit/TaxilaCockpit"));
const  TrialNewBalance = lazy(()=>import("./AccoutingReports/TrialsBalance/TrialNewBalance"));
const  TrialLedgerList = lazy(()=>import("./AccoutingReports/TrialsBalance/TrialLedgerList"));
const  BalanceNewSheet = lazy(()=>import("./AccoutingReports/BalanceSheet/BalanceNewSheet"));
const  BalanceChildSheet = lazy(()=>import("./AccoutingReports/BalanceSheet/BalanceChildSheet"));
const SInglePageInvoiceCreate = lazy(()=>import("./Transactions/Sale/SaleInvoice/SInglePageInvoiceCreate"));
const SinglePageInvoice = lazy(()=>import("./Transactions/Sale/SaleInvoice/SinglePageInvoice"));


const AppRoutes = [
  {
    path: "/layout",
    exact: false,
    component: LayoutPage,
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/General Configuration",
    exact: false,
    component:  HigherOrderComponent(ConfigurationNew),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/GeneralConfigNew",
    exact: false,
    component:  HigherOrderComponent(GeneralConfigNew),
    parent: "self",
    accessTypeId: 0,
    resourceId: 48
  },
  {
    path: "/layout/InventoryDashboard",
    exact: false,
    component:  HigherOrderComponent(InventoryDashboard),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/E-Way Bill",
    exact: false,
    component:  HigherOrderComponent(EWayBill),
    parent: "self",
    accessTypeId: 5000
  },
  {
    path: "/layout/Party Master",
    exact: false,
    component:  HigherOrderComponent(PartyMasterList),
    parent: "self",
    accessTypeId: 5000
  },
  {
    path: "/layout/Supplier Master",
    exact: false,
    component:  HigherOrderComponent(SupplierMasterList),
    parent: "self",
    accessTypeId: 5000
  },
 {
    path: "/layout/Create Party",
    exact: false,
    component:  HigherOrderComponent(PartyMasterNew),
    parent: "Supplier Master",
    accessTypeId: 5001
  },
  {
    path: "/layout/Create Party B2C",
    exact: false,
    component:  HigherOrderComponent(PartyMasterB2c),
    parent: "Supplier Master",
    accessTypeId: 5001
  },
  {
    path: "/layout/Supply Master",
    exact: false,
    component:  HigherOrderComponent(SupplyMasterList),
    parent: "self",
    accessTypeId: 5000
  },
  {
    path: "/layout/SupplyMasters",
    exact: false,
    component:  HigherOrderComponent(SupplyMasterNew),
    parent: "Item Master",
    accessTypeId: 5001
  },
  {
    path: "/layout/saleview",
    exact: false,
    component:  HigherOrderComponent(SaleView),
    parent: "Invoice",
    accessTypeId: 5000
  },
  {
    path: "/layout/QuationView",
    exact: false,
    component:  HigherOrderComponent(QuotationView),
    parent: "Invoice",
    accessTypeId: 5000
  },

  {
    path: "/layout/UseCreditView",
    exact: false,
    component:  HigherOrderComponent(UseCreditData),
    parent: "Bills",
    accessTypeId: 5001
  },

  {
    path: "/layout/purchasetemplate",
    exact: false,
    component:  HigherOrderComponent(PurchaseReturnView),
    parent: "Bills",
    accessTypeId: 5000
  },
  {
    path: "/layout/invoicebuilder",
    exact: false,
    component:  HigherOrderComponent(InvoiceBuilder),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/Reports",
    exact: false,
    component:  HigherOrderComponent(CommonReports),
    parent: "self",
    accessTypeId: 5000
  },
  {
    path: "/layout/Sale Invoice",
    exact: false,
    component:  HigherOrderComponent(SaleInvoiceList),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/Create B2C Invoice",
    exact: false,
    component:  HigherOrderComponent(SaleCustomerInvoice),
    parent: "Invoice",
    accessTypeId: 5001
  },
  {
    path: "/layout/Sale B2C View",
    exact: false,
    component:  HigherOrderComponent(saleB2CView),
    parent: "Invoice",
    accessTypeId: 5000
  },
  {
    path: "/layout/Create Invoice",
    exact: false,
    component:  HigherOrderComponent(SaleInvoiceCreate),
    parent: "Invoice",
    accessTypeId: 5001
  },
  {
    path: "/layout/Create Invoice Quick",
    exact: false,
    component: HigherOrderComponent(SInglePageInvoiceCreate),
    parent: "Invoice",
    accessTypeId: 5001
  },
  // {
  //   path: "/layout/Create Invoice Quick",
  //   exact: false,
  //   component: HigherOrderComponent(SinglePageInvoice),
  //   parent: "Invoice",
  //   accessTypeId: 5001
  // },
  {
    path: "/layout/CreateCashSales",
    exact: false,
    component:  HigherOrderComponent(CashSaleCreate),
    parent: "Invoice",
    accessTypeId: 5001
  },
  {
    path: "/layout/Create Branch Transfer",
    exact: false,
    component:  HigherOrderComponent(BranchTransfer),
    parent: "Stock Transfer",
    accessTypeId: 5001
  },
  {
    path: "/layout/Branch Transfer List",
    exact: false,
    component:  HigherOrderComponent(BranchTransferList),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/Number Series",
    exact: false,
    component:  HigherOrderComponent(DoumentHome),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/DoumentNew",
    exact: false,
    component:  HigherOrderComponent(DocumentNew),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/Category",
    exact: false,
    component:  HigherOrderComponent(CategoryDefination),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/Attributes",
    exact: false,
    component:  HigherOrderComponent(Attributes),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/Attributecreation",
    exact: false,
    component:  HigherOrderComponent(Attributecreation),
    parent: "Attributes",
    accessTypeId: 5001
  },

  {
    path: "/layout/Unit Conversions",
    exact: false,
    component:  HigherOrderComponent(UOMlanding),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/Unit Conversions New",
    exact: false,
    component:  HigherOrderComponent(UOMnew),
    parent: "Unit of Measure",
    accessTypeId: 5001
  },
  {
    path: "/layout/General Ledger",
    exact: false,
    component:  HigherOrderComponent(AccountServiceGL),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/Manage Locations",
    exact: false,
    component:  HigherOrderComponent(OrgProfile),
    parent: "self",
    accessTypeId: 0
  },
  
  {
    path: "/layout/newLocation",
    exact: false,
    component:  HigherOrderComponent(OrgLocationNew),
    // component:  HigherOrderComponent(OrgProfileNew),
    parent: "Locations",
    accessTypeId: 5003
  },
  {
    path: "/layout/Ledgers",
    exact: false,
    component:  HigherOrderComponent(Ledgers),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/LedgerCreation",
    exact: false,
    component:  HigherOrderComponent(LedgerCreation),
    parent: "Ledgers",
    accessTypeId: 5003
  },
  {
    path: "/layout/Registration",
    exact: false,
    component:  HigherOrderComponent(Registration),
    parent: "Users",
    accessTypeId: 5000
  },
  {
    path: "/layout/OrderDashboard",
    exact: false,
    component:  HigherOrderComponent(RegistrationDashboard),
    parent: "Users",
    accessTypeId: 5000
  },
  {
    path: "/layout/Home",
    exact: false,
    component:  HigherOrderComponent(DashBoardTab),
    parent: "Users",
    accessTypeId: 5000
  },

  {
    path: "/layout/RegistrationCreation",
    exact: false,
    component:  HigherOrderComponent(RegistrationCreation),
    parent: "Users",
    accessTypeId: 5001
  },
  {
    path: "/layout/PriceOveride",
    exact: false,
    component:  HigherOrderComponent(PriceOveride),
    parent: "Users",
    accessTypeId: 5001
  },
  {
    path: "/layout/PriceOverideCreation",
    exact: false,
    component:  HigherOrderComponent(PriceOverideCreation),
    parent: "Users",
    accessTypeId: 5001
  },
  {
    path: "/layout/Orgedit",
    exact: false,
    component:  HigherOrderComponent(OrgEdit),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/Users List",
    exact: false,
    component:  HigherOrderComponent(userslist),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/usersedit",
    exact: false,
    component:  HigherOrderComponent(usersedit),
    // component:  HigherOrderComponent(UserNewEdit),
    parent: "Users",
    accessTypeId: 5001
  },
  {
    path: "/layout/UserNewEdit",
    exact: false,
    component:  HigherOrderComponent(UserNewEdit),
    parent: "Users",
    accessTypeId: 5001
  },
  {
    path: "/layout/GroupsList",
    exact: false,
    component:  HigherOrderComponent(groupslist),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/groupsedit",
    exact: false,
    component:  HigherOrderComponent(groupsedit),
    parent: "Groups",
    accessTypeId: 5001
  },
  {
    path: "/layout/Payments",
    exact: false,
    component:  HigherOrderComponent(Payments),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/paymentnew",
    exact: false,
    component:  HigherOrderComponent(paymentNew),
    parent: "Payments",
    accessTypeId: 5001
  },
  {
    path: "/layout/paymentPreview",
    exact: false,
    component:  HigherOrderComponent(Preview),
    parent: "Payments",
    accessTypeId: 5000
  },
  {
    path: "/layout/Sale Receipt",
    exact: false,
    component:  HigherOrderComponent(Receipt),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/Sale Receipt New",
    exact: false,
    component:  HigherOrderComponent(ReceiptNew),
    parent: "Receipts",
    accessTypeId: 5001
  },
  {
    path: "/layout/Sale Receipt Preview",
    exact: false,
    component:  HigherOrderComponent(ReceiptPreview),
    parent: "Receipts",
    accessTypeId: 5000
  },
  {
    path: "/layout/Opening Balance",
    exact: false,
    component:  HigherOrderComponent(openingBalance),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/Manage Inventory",
    exact: false,
    component:  HigherOrderComponent(OpeningStock),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/import",
    exact: false,
    component:  HigherOrderComponent(ImportPage),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/Inventory Adjustment",
    exact: false,
    component:  HigherOrderComponent(SupplyAdjustment),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/supplypreview",
    exact: false,
    component:  HigherOrderComponent(supplypreview),
    parent: "Inventory Adjustment",
    accessTypeId: 5001
  },
  {
    path: "/layout/grouping-ctrl/adq-addresscontrol",
    exact: false,
    component:  HigherOrderComponent(AdqAddressControl),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/Sale Return",
    exact: false,
    component:  HigherOrderComponent(SaleReturn),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/salereturnnew",
    exact: false,
    component:  HigherOrderComponent(SaleReturnNew),
    parent: "Sale Return",
    accessTypeId: 5001
  },
  {
    path: "/layout/ReturnView",
    exact: false,
    component:  HigherOrderComponent(SaleReturnView),
    parent: "Sale Return",
    accessTypeId: 5000
  },
  {
    path: "/layout/Sale Debit Note",
    exact: false,
    component:  HigherOrderComponent(SaleDebitNote),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/saleDebitNew",
    exact: false,
    component:  HigherOrderComponent(SaleDebitNoteNew),
    parent: "Sale Debit Note",
    accessTypeId: 5001
  },
  {
    path: "/layout/debitview",
    exact: false,
    component:  HigherOrderComponent(SaleDebitView),
    parent: "Sale Debit Note",
    accessTypeId: 5000
  },
  {
    path: "/layout/Sale Credit Note",
    exact: false,
    component:  HigherOrderComponent(SaleCreditNote),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/SaleCreditNoteNew",
    exact: false,
    component:  HigherOrderComponent(SaleCreditNoteNew),
    parent: "Sale Credit Note",
    accessTypeId: 5001
  },
  {
    path: "/layout/creditview",
    exact: false,
    component:  HigherOrderComponent(SaleCreditView),
    parent: "Sale Credit Note",
    accessTypeId: 5000
  },
  // {
  //   path: "/layout/TrialBalance",
  //   exact: false,
  //   component:  HigherOrderComponent(TrialBalance,
  //   parent: "self",
  //   accessTypeId: 5000
  // },
  {
    path: "/layout/TrialBalance",
    exact: false,
    component:  HigherOrderComponent(TrialNewBalance),
    parent: "self",
    accessTypeId: 5000
  },
  {
    path: "/layout/TrialLedgerTransactions",
    exact: false,
    component:  HigherOrderComponent(TrialLedgerList),
    parent: "Trial Balance",
    accessTypeId: 5000
  },
  {
    path: "/layout/AccountTransactions",
    exact: false,
    component:  HigherOrderComponent(AccountTransactions),
    parent: "self",
    accessTypeId: 5000
  },
  // {
  //   path: "/layout/Journal Reports",
  //   exact: false,
  //   component:  HigherOrderComponent(JournalReports,
  //   parent: "self",
  //   accessTypeId: 5000
  // },
  {
    path: "/layout/Journal Reports",
    exact: false,
    component:  HigherOrderComponent(ReportsDateSelection),
    parent: "self",
    accessTypeId: 5000
  },
  {
    path: "/layout/profitandloss",
    exact: false,
    component:  HigherOrderComponent(profitandloss),
    parent: "self",
    accessTypeId: 5000
  },
  {
    path: "/layout/profitandlossLedgerTransactions",
    exact: false,
    component:  HigherOrderComponent(TrialLedgerList),
    parent: "Profit & Loss",
    accessTypeId: 5000
  },
  {
    path: "/layout/balancesheet",
    exact: false,
    component:  HigherOrderComponent(BalanceNewSheet),
    parent: "self",
    accessTypeId: 5000
  },
  {
    path: "/layout/balanceSubsheet",
    exact: false,
    component:  HigherOrderComponent(BalanceChildSheet),
    parent: "Balance Sheet",
    accessTypeId: 5000
  },
  {
    path: "/layout/balanceLedgerTransations",
    exact: false,
    component:  HigherOrderComponent(TrialLedgerList),
    parent: "Balance Sheet",
    accessTypeId: 5000
  },
  {
    path: "/layout/noteno",
    exact: false,
    component:  HigherOrderComponent(noteno),
    parent: "self",
    accessTypeId: 5000
  },
  {
    path: "/layout/NIC Configuration",
    exact: false,
    component:  HigherOrderComponent(NicConfiguration),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/saleEWayBill",
    exact: false,
    component:  HigherOrderComponent(SaleEwayBill),
    parent: "E-Way Bill",
    accessTypeId: 5000
  },
  {
    path: "/layout/Additional Field",
    exact: false,
    component:  HigherOrderComponent(AdditionalField),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/AdditionalFieldNew",
    exact: false,
    component:  HigherOrderComponent(AdditionalFieldNew),
    parent: "Additional Field",
    accessTypeId: 5001
  },
  {
    path: "/layout/Manage Role",
    exact: false,
    component:  HigherOrderComponent(ManageRole),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/Manage Role New",
    exact: false,
    component:  HigherOrderComponent(ManageRoleNew),
    parent: "Roles",
    accessTypeId: 5001
  },

  {
    path: "/layout/Purchase Order",
    exact: false,
    component:  HigherOrderComponent(PurchaseOrder),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/PurchaseOrderview",
    exact: false,
    component:  HigherOrderComponent(PurchaseOrderview),
    parent: "Purchase Order",
    accessTypeId: 5000
  },
  {
    path: "/layout/Purchase Order New",
    exact: false,
    component:  HigherOrderComponent(PurchaseOrderNew),
    parent: "Purchase Order",
    accessTypeId: 5001
  },
  {
    path: "/layout/Data Import",
    exact: false,
    component:  HigherOrderComponent(DataImport),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/Purchase Bills",
    exact: false,
    component:  HigherOrderComponent(PurchaseInvoice),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/Purchase Bill New",
    exact: false,
    component:  HigherOrderComponent(PurchaseInvoiceNew),
    parent: "Bills",
    accessTypeId: 5001
  },
  {
    path: "/layout/Overseas Purchase",
    exact: false,
    component:  HigherOrderComponent(OverseasPurchaseList),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/OverseasPurchaseNew",
    exact: false,
    component:  HigherOrderComponent(OverseasPurchaseNew),
    parent: "Overseas Purchase",
    accessTypeId: 5001
  },
  {
    path: "/layout/OverseasPurchaseView",
    exact: false,
    component:  HigherOrderComponent(OverseasPurchaseView),
    parent: "Overseas purchase",
    accessTypeId: 5000
  },
  {
    path: "/layout/Quotation",
    exact: false,
    component:  HigherOrderComponent(QuotationList),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/QuotationParent",
    exact: false,
    component:  HigherOrderComponent(QuotationParent),
    parent: "Quotation",
    accessTypeId: 5000
  },
  {
    path: "/layout/PurchaseView",
    exact: false,
    component:  HigherOrderComponent(PurchaseView),
    parent: "Bills",
    accessTypeId: 5000
  },
  {
    path: "/layout/Purchase Return",
    exact: false,
    component:  HigherOrderComponent(PurchaseReturn),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/Purchase Return New",
    exact: false,
    component:  HigherOrderComponent(PurchaseReturnNew),
    parent: "Purchase Return",
    accessTypeId: 5001
  },
  {
    path: "/layout/Purchase Credit Note",
    exact: false,
    component:  HigherOrderComponent(PurchaseCreditNote),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/Purchase Credit View",
    exact: false,
    component:  HigherOrderComponent(PurchaseCreditView),
    parent: "Purchase Credit Note",
    accessTypeId: 5000
  },
  {
    path: "/layout/Purchase Credit Note New",
    exact: false,
    component:  HigherOrderComponent(PurchaseCreditNoteNew),
    parent: "Purchase Credit Note",
    accessTypeId: 5001
  },
  {
    path: "/layout/Purchase Debit Note",
    exact: false,
    component:  HigherOrderComponent(PurchaseDebitNote),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/Purchase Debit View",
    exact: false,
    component:  HigherOrderComponent(PurchaseDebitview),
    parent: "Purchase Debit Note",
    accessTypeId: 5000
  },
  {
    path: "/layout/Purchase Debit Note New",
    exact: false,
    component:  HigherOrderComponent(PurchaseDebitNoteNew),
    parent: "Purchase Debit Note",
    accessTypeId: 5001
  },
  {
    path: "/layout/Purchase Template New",
    exact: false,
    component:  HigherOrderComponent(PurchaseReturnView),
    parent: "Purchase Return",
    accessTypeId: 5000
  },
  {
    path: "/layout/Sale Order",
    exact: false,
    component:  HigherOrderComponent(ChannelOrderList),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/WorkflowOrderlist",
    exact: false,
    component:  HigherOrderComponent(ChannelOrderList),
    // component:  HigherOrderComponent(WorkflowOrderlist),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/flowOrderView",
    exact: false,
    // component:  HigherOrderComponent(WorkflowOrderView,
    component:  HigherOrderComponent(ChannelOrder),
    parent: "Sale Order",
    accessTypeId: 5000
  },
  {
    path: "/layout/ReturnOrderlist",
    exact: false,
    component:  HigherOrderComponent(ReturnOrderList),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/returnOrderView",
    exact: false,
    component:  HigherOrderComponent(ReturnOrderView),
    parent: "Sale Order",
    accessTypeId: 5000
  },
  {
    path: "/layout/WorkflowOrderNew",
    exact: false,
    component:  HigherOrderComponent(WorkflowOrderNew),
    parent: "Sale Order",
    accessTypeId: 5001
  },
  {
    path: "/layout/SaleOrderNew",
    exact: false,
    component:  HigherOrderComponent(WorkflowOrderNew),
    parent: "Sale Order",
    accessTypeId: 5001
  },
  {
    path: "/layout/SaleOrderView",
    exact: false,
    component:  HigherOrderComponent(ChannelOrder),
    parent: "Sale Order",
    accessTypeId: 5000
  },
  {
    path: "/layout/ClientUsers",
    exact: false,
    component:  HigherOrderComponent(ClientUsers),
    parent: "self",
    accessTypeId: 5000
  },
  {
    path: "/layout/EInvoiceListView",
    exact: false,
    component:  HigherOrderComponent(EInvoiceListView),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/KSACreation",
    exact: false,
    component:  HigherOrderComponent(KSACreation),
    parent: "Sale Order",
    accessTypeId: 5001
  },
  {
    path: "/layout/PoSNewCreation",
    exact: false,
    component:  HigherOrderComponent(PoSNewCreation),
    parent: "Sale Order",
    accessTypeId: 5001
  },
  {
    path: "/layout/mastercatalogue",
    exact: false,
    component:  HigherOrderComponent(MasterCatalogue),
    parent: "CatManagement",
    accessTypeId: 5003
  },
  {
    path: "/layout/sharedcatalogue",
    exact: false,
    component:  HigherOrderComponent(SharedCatalogue),
    parent: "CatManagement",
    accessTypeId: 5003
  },
  {
    path: "/layout/priceoverrides",
    exact: false,
    component:  HigherOrderComponent(PriceOverrides),
    parent: "CatManagement",
    accessTypeId: 5003
  },

  {
    path: "/layout/MappedChannels",
    exact: false,
    component:  HigherOrderComponent(MappedChannels),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/Overrides",
    exact: false,
    component:  HigherOrderComponent(Overrides),
    parent: "self",
    accessTypeId: 0
  },

  {
  //  path: "/layout/AddCatelogueItemMapping",
    path: "/layout/ChannelDetails",
    exact: false,
    component:  HigherOrderComponent(ChannelDetails),
    parent: "Catalogue Management",
    accessTypeId: 5001
},
  {
    path: "/layout/AddChannelDetail",
    exact: false,
    component:  HigherOrderComponent(AddChannelDetail),
    parent: "Sales Channel",
    accessTypeId: 5001
  },
  {
    path: "/layout/FileImport",
    exact: false,
    component:  HigherOrderComponent(FileImportList),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/FileImportNew",
    exact: false,
    component:  HigherOrderComponent(FileImportNew),
    parent: "Data Import",
    accessTypeId: 5001
  },
  {
    path: "/layout/journal",
    exact: false,
    component:  HigherOrderComponent(JournalsList),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/journalNew",
    exact: false,
    component:  HigherOrderComponent(JournalsNew),
    parent: "Manual Journals",
    accessTypeId: 5001
  },
  {
    path: "/layout/Advance Payments",
    exact: false,
    component:  HigherOrderComponent(AdvancePayments),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/AdvPaymentsNew",
    exact: false,
    component:  HigherOrderComponent(AdvPaymentsNew),
    parent: "Advance Payments",
    accessTypeId: 5001
  },
  {
    path: "/layout/AdvPaymentsView",
    exact: false,
    component:  HigherOrderComponent(AdvancePaymentsView),
    parent: "Advance Payments",
    accessTypeId: 5000
  },
  {
    path: "/layout/Advance Receipts",
    exact: false,
    component:  HigherOrderComponent(AdvanceReceipts),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/AdvReceiptsNew",
    exact: false,
    component:  HigherOrderComponent(AdvReceiptNew),
    parent: "Advance Receipts",
    accessTypeId: 5001
  },
  {
    path: "/layout/AdvReceiptsView",
    exact: false,
    component:  HigherOrderComponent(AdvanceReceiptsView),
    parent: "Advance Receipts",
    accessTypeId: 5000
  },
  {
    path: "/layout/ExpenseNew",
    exact: false,
    component:  HigherOrderComponent(ExpenseNew),
    parent: "Expenses",
    accessTypeId: 5001
  },
  {
    path: "/layout/ExpensePreview",
    exact: true,
    component:  HigherOrderComponent(ExpensePreview),
    parent: "Expenses",
    accessTypeId: 5000
  },
  {
    path: "/layout/ExpenseList",
    exact: true,
    component:  HigherOrderComponent(ExpenseList),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/Transfer View",
    exact: false,
    component:  HigherOrderComponent(TransferView),
    parent: "Stock Transfer",
    accessTypeId: 5000
  },
  {
    path: "/layout/Transfer Challan",
    exact: false,
    component:  HigherOrderComponent(TransferChallan),
    parent: "Stock Transfer",
    accessTypeId: 5000
  },
  {
    path: "/layout/CashBankList",
    exact: false,
    component:  HigherOrderComponent(CashBank),
    parent: "self",
    accessTypeId: 5000
  },
  {
    path: "/layout/TradeReceivable",
    exact: false,
    component:  HigherOrderComponent(TradeReceivables),
    parent: "self",
    accessTypeId: 5000
  },
  {
    path: "/layout/TradePayable",
    exact: false,
    component:  HigherOrderComponent(TradePayables),
    parent: "self",
    accessTypeId: 5000
  },
  {
    path: "/layout/GstAdjusmentNew",
    exact: false,
    component:  HigherOrderComponent(GstAdjustmentNew),
    parent: "GST Returns",
    accessTypeId: 5003
  },
  {
    path: "/layout/GstReturns",
    exact: false,
    component:  HigherOrderComponent(GstReturns),
    parent: "self",
    accessTypeId: 5003
  },
  {
    path: "/layout/TaxillaCockpit",
    exact: false,
    component:  HigherOrderComponent(TaxilaCockpit),
    parent: "GST Returns",
    accessTypeId: 5003
  },
  {
    path: "/layout/gstr-3B",
    exact: false,
    component:  HigherOrderComponent(Gstr3B),
    parent: "GST Returns",
    accessTypeId: 5003
  },
  {
    path: "/layout/gstr1",
    exact: false,
    component:  HigherOrderComponent(Gstr1),
    parent: "GST Returns",
    accessTypeId: 5003
  },
  {
    path: "/layout/Overseas Sale",
    exact: false,
    component:  HigherOrderComponent(OverseasSaleList),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/OverseasSaleNew",
    exact: false,
    component:  HigherOrderComponent(OverseasSaleNew),
    parent: "Overseas Sale",
    accessTypeId: 5001
  },
  {
    path: "/layout/OverseasSaleView",
    exact: false,
    component:  HigherOrderComponent(OverseasSaleView),
    parent: "Overseas Sale",
    accessTypeId: 5000
  },

  {
    path: "/layout/CashSalesList",
    exact: false,
    component:  HigherOrderComponent(CashSalesList),
    parent: "Invoice",
    accessTypeId: 5001
  },
  {
    path: "/layout/CashSalesView",
    exact: false,
    component:  HigherOrderComponent(CashSalesView),
    parent: "Invoice",
    accessTypeId: 5000,
  },
  {
    path: "/layout/PoS",
    exact: false,
    component:  HigherOrderComponent(SaleCustomerList),
    parent: "self",
    accessTypeId: 5000
  },
  {
    path: "/layout/ProductListing",
    exact: false,
    component:  HigherOrderComponent(ProductListing),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/ProductDetails",
    exact: false,
    component:  HigherOrderComponent(ProductDetails),
    parent: "Product",
    accessTypeId: 5000
  },
  {
    path: "/layout/ChartOfAccounts",
    exact: false,
    component:  HigherOrderComponent(ChartOfAccounts),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/ChartOfAccountsNewEdit",
    exact: false,
    component:  HigherOrderComponent(ChartOfAccountsNewEdit),
    parent: "Chart of Accounts",
    accessTypeId: 5003
  },
  {
    path: "/layout/JournalVouchers",
    exact: false,
    component:  HigherOrderComponent(JournalVoucherView),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/JournalVoucherCreation",
    exact: false,
    component:  HigherOrderComponent(JournalVoucherCreation),
    parent: "Journal vouchers",
    accessTypeId: 5003
  },
  {
    path: "/layout/Ledgerpostings",
    exact: false,
    component:  HigherOrderComponent(Ledgerposting),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/LedgerPostingCreation",
    exact: false,
    component:  HigherOrderComponent(Ledgerpostingcreation ),
    parent: "Ledger Postings",
    accessTypeId:5003
},
{
    path: "/layout/MSIReports",
    exact: false,
    component:  HigherOrderComponent(MSIReports),
    parent: "self",
    accessTypeId: 5000
  },
  {
    path: "/layout/MISReports",
    exact: false,
    component:  HigherOrderComponent(MISReports),
    parent: "MIS Reports",
    accessTypeId: 5003
  },
  {
    path: "/layout/ReportsCommon",
    exact: false,
    component:  HigherOrderComponent(ReportsCommon),
    parent: "Reports",
    accessTypeId: 5000
  },
  {
    path: "/layout/AccountReports",
    exact: false,
    component:  HigherOrderComponent(ReportsDateSelection),
    parent: "self",
    accessTypeId: 0
  },
  {
    path: "/layout/subscriptions",
    exact: false,
    component:  HigherOrderComponent(Subscriptions),
    parent: "self",
    accessTypeId: 5003
  },
  {
    path: "/layout/Supply Master",
    exact: false,
    component:  HigherOrderComponent(SupplyMasterList),
    parent: "Catalogue Management",
    accessTypeId: 5000
  },
  {
    path: "/layout/CatelogueCreation",
    exact: false,
    component:  HigherOrderComponent(CatelogueCreation),
    parent: "Catalogue Management",
    accessTypeId: 5001
  },

  {
    path: "/layout/CatelogueDetails",
    exact: false,
    component:  HigherOrderComponent(CatelogueDetails),
    parent: "Catalogue Management",
    accessTypeId: 5000
  },
  {
    path: "/layout/AddCatelogueDetails",
    exact: false,
    component:  HigherOrderComponent(AddCatelogueDetails),
    parent: "Catalogue Management",
    accessTypeId: 5000
  },
  {
    path: "/layout/CatelogueAllocation",
    exact: false,
    component:  HigherOrderComponent(CatelogueAllocation),
    parent: "Catalogue Management",
    accessTypeId: 5000
  },
  {
    path: "/layout/AddCatelogueAllocationDetails",
    exact: false,
    component:  HigherOrderComponent(AddCatelogueAllocationDetails),
    parent: "Catalogue Management",
    accessTypeId: 5000
  },
  {
    path: "/layout/CatelogueItemMapping",
    exact: false,
    component:  HigherOrderComponent(CatelogueItemMapping),
    parent: "Catalogue Management",
    accessTypeId: 5000
  },
  {
    path: "/layout/AddCatelogueItemMapping",
    exact: false,
    component:  HigherOrderComponent(AddCatelogueItemMapping),
    parent: "Catalogue Management",
    accessTypeId: 5000
  },
  {
    path: "/layout/CatelogueMapping",
    exact: false,
    component:  HigherOrderComponent(CatelogueMapping),
    parent: "self",
    accessTypeId: 5000
  },
  {
    path: "/layout/allopeningstock",
    exact: false,
    component:  HigherOrderComponent(AllOpeningStock),
    parent: "self",
    accessTypeId: 5000
  },
  {
    path: "/layout/OutStandingCreditors",
    exact: false,
    component:  HigherOrderComponent(OutStandingCreditors),
    parent: "self",
    accessTypeId: 5003
  },
  {
    path: "/layout/OutStandingDebitors",
    exact: false,
    component:  HigherOrderComponent(OutStandingDebitors),
    parent: "self",
    accessTypeId: 5003
  },
]
export default AppRoutes;